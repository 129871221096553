import { fade, makeStyles } from '@material-ui/core/styles/';

export default makeStyles(theme => ({
  form: {
    width: '100%',
    padding: `${theme.spacing(5)}px ${theme.spacing(3)}px`
  },
  formControl: {
    width: '100%',
    height: ({ id }) => id !== "message" ? 95 : 220,
  },
  input: {
    background: theme.palette.secondary.main,
    borderRadius: theme.spacing(.5),
    border: `1px solid ${theme.palette.neutral.main}`,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      background: theme.palette.secondary.main,
    },
    '&.Mui-focused': {
      background: theme.palette.secondary.main,
      boxShadow: `${fade(theme.palette.primary.main, 0.05)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    }
  },
  helper: {
    color: theme.palette.secondary.main,
    marginLeft: 0
  },
  helperText: {
    marginLeft: 3,
    marginTop: 3, // align text with icon
  }
}));
