import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(theme => ({
  imageWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  image: {
    width: '100%',
    objectFit: 'cover'
  },
  formWrapper: {
    background: '#0E5C45', // Theme non reconnu dans les styles de pages
  }
}));