import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from '../components/layout';
import Title from '../components/Title';
import ContactForm from '../components/Forms/ContactForm'

import { sendContactFormLaunched } from '../state/reducer';
import styles from '../pagesStyles/contactStyles';

const ContactPage = ({ data }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const md = data.markdownRemark.frontmatter;

  const initialValues = {
    lastName: '',
    firstName: '',
    email: '',
    address: '',
    city: '',
    zipCode: '',
    phone: '',
    message: '',
  }
  const ZIPCODE_ERROR_MESSAGE = "Merci de renseigner un code postal valide";
  const PHONE_NUMBER_ERROR_MESSAGE = "Merci de renseigner un numéro de téléphone valide";

  const validationSchema = Yup.object().shape({
    lastName: Yup.string().trim().required("Merci de renseigner votre nom de famille"),
    firstName: Yup.string().trim().required("Merci de renseigner votre prénom"),
    email: Yup.string().trim().email("Merci de renseigner un format d'email valide").required("L'adresse email est obligatoire"),
    address: Yup.string().trim().required("L'adresse du cabinet est obligatoire"),
    city: Yup.string().trim().required("La ville est obligatoire"),
    zipCode: Yup.string()
      .matches(/^[0-9]+$/, ZIPCODE_ERROR_MESSAGE)
      .min(5, ZIPCODE_ERROR_MESSAGE)
      .max(5, ZIPCODE_ERROR_MESSAGE)
      .required("Le code postal est obligatoire"),
    phone: Yup.string().matches(/^\+?\d+$/, PHONE_NUMBER_ERROR_MESSAGE).trim().min(10, PHONE_NUMBER_ERROR_MESSAGE).required("Le numéro de téléphone est obligatoire"),
    message: Yup.string().trim().required("Le message est obligatoire"),
  });

  const sendContactForm = (values) => {
    dispatch(sendContactFormLaunched({ ...values, type: 'PUBLIC_MESSAGE' }));
  }

  return (
    <Layout>
      
      <Helmet>
        <html lang="fr" />
        <title>{md.SEOtitle}</title>
        <meta name="description" content={md.SEOdescription} />
      </Helmet>

      <Title title={md.title} />
      <Grid container>
        <Grid container item xs={12} md={6} className={classes.imageWrapper}>
          <GatsbyImage image={md.image.childImageSharp.gatsbyImageData} alt={md.alt} className={classes.image} loading="eager" />
        </Grid>

        <Grid container item xs={12} md={6} className={classes.formWrapper}>
          <Formik
            onSubmit={sendContactForm}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {(props) => <ContactForm {...props} />}
          </Formik>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/contact.md/"}) {
        frontmatter {
            SEOtitle
            SEOdescription
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
            title
        }
    }
}
`