import React from 'react'
import PropTypes from 'prop-types';
import { FilledInput, FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import clsx from 'clsx';

import styles from '../../styles';
import { capitalizeString } from '../../../../utils/services/format';
import globalStyles from '../../../../utils/globalStyles';

const CustomTextField = ({ id, error, helperText, handleChange, handleBlur, label, value }) => {
  const classes = styles({ id });
  const globalClasses = globalStyles();

  return (
    <FormControl
      variant="filled"
      className={classes.formControl}
    >
      <InputLabel
        error={error}
        classes={{ root: classes.rootError, error: classes.error }}
        htmlFor={id}>
        {capitalizeString(label)}
      </InputLabel>
      <FilledInput
        classes={{ root: classes.input }}
        id={id}
        name={id}
        disableUnderline
        multiline={id === 'message'}
        rows={8}
        error={error}
        // placeholder={id === "email" ? "exemple@exemple.com" : null}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        type={id === "zipCode" ? "number" : "text"}
      />
      <FormHelperText
        classes={{ root: classes.rootError, error: classes.error }}
        htmlFor={id}>
        {helperText && (
          <span className={clsx(globalClasses.verticalCenter, classes.helper)}>
            {error && <Error />}
            <span className={classes.helperText}>{helperText}</span>
          </span>
        )}
      </FormHelperText>
    </FormControl>
  )
}

export default CustomTextField

CustomTextField.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired
};