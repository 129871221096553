import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import CustomTextField from '../Inputs/CustomTextField'
import CustomButton from '../../CustomButton/customButton';
import CustomLoader from '../../CustomLoader/';

import { hasNoEmptyValue, switchLabel } from '../../../utils/services/format';
import styles from '../styles';

const ContactForm = ({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm }) => {
  const classes = styles();

  const isValid = hasNoEmptyValue(values);
  const hasErrors = Object.keys(errors).length > 0;

  const { shouldResetForm, isLoading } = useSelector(state => ({
    shouldResetForm: state.getIn(['root', 'shouldResetForm']),
    isLoading: state.getIn(['root', 'isLoading']),
  }));

  useEffect(() => {
    if (shouldResetForm) {
      resetForm()
    }
  }, [shouldResetForm, resetForm])

  const names = ['firstName', 'lastName'];
  const addresses = ['email', 'address'];
  const city = ['zipCode', 'city'];
  const rest = ['phone', 'message']

  return (
    <form
      onSubmit={handleSubmit}
      className={classes.form}
    >
      <Grid container direction="column" alignItems="center" justify="center">
        <Grid container spacing={2}>
          {names.map((field, key) => {
            return (
              <Grid item xs={12} sm={6} md={12} lg={6} key={key}>
                <CustomTextField
                  id={field}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values[field]}
                  label={switchLabel(field)}
                  error={!!touched[field] && !!errors[field]}
                  helperText={touched[field] && errors[field]}
                />
              </Grid>
            )
          })}
        </Grid>

        <Grid container>
          {addresses.map((field, key) => {
            return (
              <Grid item xs={12} key={key}>
                <CustomTextField
                  id={field}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values[field]}
                  label={switchLabel(field)}
                  error={!!touched[field] && !!errors[field]}
                  helperText={touched[field] && errors[field]}
                />
              </Grid>
            )
          })}
        </Grid>


        <Grid container spacing={2}>
          {city.map((field, key) => {
            return (
              <Grid item xs={12} sm={key === 0 ? 5 : 7} md={12} lg={key === 0 ? 5 : 7} key={key}>
                <CustomTextField
                  id={field}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values[field]}
                  label={switchLabel(field)}
                  error={!!touched[field] && !!errors[field]}
                  helperText={touched[field] && errors[field]}
                />
              </Grid>
            )
          })}
        </Grid>

        <Grid container>
          {rest.map((field, key) => {
            return (
              <Grid item xs={12} key={key}>
                <CustomTextField
                  id={field}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values[field]}
                  label={switchLabel(field)}
                  error={!!touched[field] && !!errors[field]}
                  helperText={touched[field] && errors[field]}
                />
              </Grid>
            )
          })}
        </Grid>

        <Box mt={3}>
          <CustomButton type="submit" disabled={!isValid || hasErrors}>
            {isLoading
              ? <CustomLoader isButton />
              : "Envoyer"
            }
          </CustomButton>
        </Box>
      </Grid>
    </form>
  )
}

export default ContactForm;

ContactForm.propTypes = {
  values: PropTypes.shape({
    lastName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zipCode: PropTypes.PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired,
    phone: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
  }).isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired
};
